* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	position: relative;
}

body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

main {
	margin-top: 10vh;
	margin-left: 20%;
	padding-top: 1rem;
	padding-left: 1rem;
}
